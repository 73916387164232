import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import {
  NavigationInstruction,
  PipelineResult,
  RouterEvent,
  Router
} from 'aurelia-router';
import { User } from 'components/models/UserModel';
import { SingletonService } from 'singleton';
import {I18N} from 'aurelia-i18n';
import { NotificationHttpClient } from "http_clients/NotificationHttpClient";
import { UsersHttpClients } from "http_clients/UsersHttpClients";
import { NotificationModel } from "components/models/NotificationModel";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { DialogService } from 'aurelia-dialog';
import { Add } from 'pages/contacts/add/add';

@autoinject()
export class FooterMenu {
  private currentInstruction = { config: {
                                    route: "home"
                                  }
                                } as NavigationInstruction;
  private navEvent;
  // private notInvited = this.i18n.tr("home.alerts.not_invited");
  private me: User;
  private allNotificationInformation;
  /* private total: number;
  private notificationResponse; */

  constructor(private router: Router,
    private ea: EventAggregator, 
    private dialogService: DialogService,
    private singleton : SingletonService, 
    private i18n: I18N,
    private notificationHttpClient: NotificationHttpClient,
    private userHttpClient: UsersHttpClients,) {
    this.me = this.singleton.getMe();
    this.getMyNotification();

    this.navEvent = this.ea.subscribe(RouterEvent.Success, (event: { instruction: NavigationInstruction; result: PipelineResult }) => {
      // console.log("current route: " + JSON.stringify(event.instruction));
      // console.log(event.result)
      this.currentInstruction = event.instruction 
    })
  }

  gotoFriends() {
    if (this.me.verified == false)
      alert(this.i18n.tr("home.alerts.not_invited"));
    else {
      this.router.navigateToRoute("contacts");
    }
  }

  goBack() {
    this.router.navigateBack()
  }

  goImport() {
    this.router.navigateToRoute("bitcoin_import");
  }

  goToChooseWallet() {
    this.singleton.setIsReturnBack(false);
    this.router.navigateToRoute("choose_wallet");
  }
  // activate() {
    
  // }
  deactivate() {
    this.navEvent.dispose();
  }

  // bind() {
    
  // }

  mainButton() {
    if(this.currentInstruction.config.route == 'home')
      this.router.navigateToRoute('scan_qr_code');
      if(this.currentInstruction.config.route == 'payments')
      this.router.navigateToRoute('scan_qr_code');
    if(this.currentInstruction.config.route == 'notifications')
      this.router.navigateToRoute('scan_qr_code');
      if(this.currentInstruction.config.route == 'profile')
      this.router.navigateToRoute('scan_qr_code');
    if(this.currentInstruction.config.route == 'choose_wallet') 
      this.router.navigateToRoute('choose_type_account'); 
    if(this.currentInstruction.config.route == 'contacts/:page')
    this.dialogService.open({ viewModel: Add, lock: false })
    if(this.currentInstruction.config.route == 'contacts_select')
    this.router.navigateToRoute('create_recipients');
    if(this.currentInstruction.config.route == 'savepot_home')
    this.router.navigateToRoute('savepot_add');
    if(this.currentInstruction.config.route == 'ordinals')
    this.router.navigate('/ordinals/inscribe');
  }

  async getMyNotification() {
    try{
        this.me = this.singleton.getMe()
        let request = await this.notificationHttpClient.fetch('/notification') ///filtered?owner_id=' + this.me._id + "&limit='1000'
        let is200Ok = await checkResponseStatus(request)
        this.allNotificationInformation = await is200Ok.json() //request
        this.UpdateToSeenEveryNotification(this.allNotificationInformation)
        console.log(this.allNotificationInformation);
        //console.log(this.allNotificationInformation)
        //console.log("getnotif")
        //console.log(this.draggableDiv);
        //console.log(this.draggableDiv.length);   

    }
    catch(e){
        //console.log(e)
    }
  }

  async UpdateEveryNotification(idOfEveryNotification) {
    //console.log(idOfEveryNotification)
    let request = await this.notificationHttpClient.fetch('/notification/' + idOfEveryNotification, {
        method: 'PATCH',
        body: JSON.stringify({
            seen: true,
        })
    })
  }

  async UpdateToSeenEveryNotification(allNotificationInformation) {
    try {
        for (let i = 0; i < allNotificationInformation.totalDocs; i++) {
            //console.log(i)
            //console.log(allNotificationInformation.docs[i]._id)
            await this.UpdateEveryNotification(allNotificationInformation.docs[i]._id)
        }
    } catch (e) {
        //console.log(e)
    }
  }

  /* async getNotificationNumber() {
    let request = await this.notificationHttpClient.fetch("/notification");
    let is200Ok = await checkResponseStatus(request);
    this.notificationResponse = await is200Ok.json(request);
    this.total = 0; // initialiser le total à 0
    // parcourir le tableau de notificationResponse
    for (let notification of this.notificationResponse) {
      console.log(notification);
      // si la notification n'a pas été vue
      if (notification.seen == false) {
        // ajouter 1 au total
        this.total++;
      }
    }
    // console.log(this.notificationResponse[0]);
    console.log(this.total + "total notification");
  } */
}
