// Module
var code = `<template>
  <require from="./header-title-notifications.scss"></require>
  <section id="headertitlenotifications"
    class.bind="currentInstruction.config.auth && currentInstruction.config.navModel.isActive ? '' : 'no_display'">
    <div class="flex-column">
      <div class="flex-row top">
        <div class="left">
          <h1>\${title}</h1>
          <p>\${subtitle}</p>
        </div>
        <!-- <div class="flex-grow"></div>
        <div class="right flex-row">
          <!-- <button class="btn-fresh btn-fresh-secondary edit">Edit section</button>
          <button 
            class="btn-fresh btn-fresh-primary" 
            click.delegate="makePayment()">
            <img src="/static/svg/up_arrow.svg" />
            <span t="home.send"></span>
          </button>
          <button 
            class="btn-fresh btn-fresh-secondary last" 
            click.delegate="receivePayment()">
            <img src="/static/svg/down_arrow.svg" alt="">
            <span t="home.receive"></span>
          </button>
        </div>-->
      </div>
      <div class="flex-row bottom">
        <ul>
          <li 
            repeat.for="menuItem of menuItems"
            class.bind="(tabs === \$index) ? 'active' : ''"
            click.delegate="tabs = \$index">
            <span class="name">
              \${menuItem.name}
            </span>
            <span class="notifs" if.bind="menuItem.notifications >= 0">
              \${menuItem.notifications}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
`;
// Exports
export default code;