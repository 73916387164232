// Module
var code = `<template>
  <require from="./header-title-profile.scss"></require>
  <section
    id="headertitleprofile"
    class.bind="currentInstruction.config.auth && currentInstruction.config.navModel.isActive ? '' : 'no_display'"
  >
    <div class="flex-column">
      <div class="flex-row top">
        <div class="left">
          <h1>\${title}</h1>
          <p class="subtitle">\${subtitle}</p>
          <div class="flex-grow"></div>
        </div>
        <div class="flex-grow"></div>
        <!-- <div class="right flex-row">
          <button
            class="btn-fresh btn-fresh-secondary"
            if.bind="!isEditing && currentInstruction.params.page === 'mine'"
            t="contacts.editing"
            click.delegate="isEditing = !isEditing"
          ></button>
          <button
            class="btn-fresh btn-fresh-secondary"
            if.bind="isEditing && currentInstruction.params.page === 'mine'"
            t="contacts.stop_editing"
            click.delegate="isEditing = !isEditing"
          ></button>
          <button
            class="btn-fresh btn-fresh-primary"
            click.delegate="addContact()"
            t="contacts.add"
          ></button>
        </div> -->
      </div>
      <div class="flex-row bottom">
        <ul>
          <li
            repeat.for="menuItem of menuItems"
            class.bind="(currentInstruction.config.route === 'contacts/:page')
              && (currentInstruction.params.page === menuItem.url) ? 'active' : ''"
            click.delegate="router.navigateToRoute('contacts', { page: menuItem.url })"
          >
            <span class="name"> \${menuItem.name} </span>
            <span class="notifs" if.bind="menuItem.notifications > 0">
              \${menuItem.notifications}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
`;
// Exports
export default code;