// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:export {
  breakpoint-mobile: 30rem;
  mobile: 30rem;
}

/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
section#footer_menu {
  align-items: flex-end;
  background-color: transparent;
  background-position: bottom center;
  background-size: cover;
  margin: 0;
  padding: 0;
  display: flex;
  /* Safari 11+ */
}
section#footer_menu div.left {
  align-content: center;
  align-self: flex-end;
  background-color: rgba(23, 26, 33, 0.85);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  justify-content: space-evenly;
  flex: 9;
  height: 4.5rem;
  padding: 1rem;
  width: 70%;
}
section#footer_menu div.left img {
  object-fit: contain;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
section#footer_menu div.left div.wallet img {
  width: 1.8rem;
}
section#footer_menu div.left div.blue_circle {
  background-color: #0681b1;
  border-radius: 50%;
  height: 1.3rem;
  justify-content: center;
  margin: 1rem 0rem 0rem 0.5rem;
  position: absolute;
  width: 1.3rem;
}
section#footer_menu div.left div.blue_circle p {
  color: #dfe0e2;
  margin: auto;
  font-size: 11px;
}
section#footer_menu div.left .white_border {
  border: 2px solid #0f131a;
}
section#footer_menu div.right {
  align-items: center;
  align-self: flex-end;
  background-color: transparent;
  border-right: none;
  background: radial-gradient(2rem 2rem at 50% 0%, transparent 100%, transparent 3rem, rgba(23, 26, 33, 0.85) 3.1rem);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background-clip: border-box;
  background-clip: content-box;
  display: flex;
  flex: 4;
  justify-content: center;
  margin: 0px;
  padding: 0;
  height: 4.5rem;
}
section#footer_menu div.right div.bg {
  aspect-ratio: 1/1;
  align-items: center;
  border-radius: 50%;
  background-color: #0681b1;
  cursor: pointer;
  display: flex;
  height: 5rem;
  justify-content: center;
  padding: 1.25rem;
  position: relative;
  bottom: 2.25rem;
  z-index: 30;
}
section#footer_menu div.right div.bg img {
  width: 2rem;
}
section#footer_menu div.right div.bg span {
  color: #dfe0e2;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 2.1rem;
  font-weight: 400;
}
section#footer_menu div.far_right {
  background-color: rgba(23, 26, 33, 0.85);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  clip-path: inset(-5px 0rem 0px 0px);
  height: 4.5rem;
  width: 1rem;
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    section#footer_menu .safari_only {
      padding-bottom: 2rem;
    }
  }
}

@media only screen and (max-width: 40rem) {
  section#footer_menu {
    max-height: calc(100vh - 4rem);
  }
}
section#footer_menu.hidden {
  height: 0px !important;
  opacity: 0;
  overflow: hidden;
  transform: translateY(7rem);
}`, "",{"version":3,"sources":["webpack://./src/breakpoints.scss","webpack://./src/components/footer-menu/footer-menu.scss","webpack://./src/colors.scss"],"names":[],"mappings":"AA+CA;EAEQ,wBAxBa;EA2BjB,aA3BiB;ACtBrB;;ACHA;;;;EAAA;ADGA;EACE,qBAAA;EAGA,6BAAA;EACA,kCAAA;EACA,sBAAA;EACA,SAAA;EACA,UAAA;EACA,aAAA;EAyIA,eAAA;AAlIF;AAJE;EACE,qBAAA;EACA,oBAAA;EACA,wCAAA;EACA,2BAAA;EACA,mCAAA;EAGA,aAAA;EACA,6BAAA;EACA,OAAA;EACA,cAAA;EACA,aAAA;EACA,UAAA;AAIJ;AADI;EACE,mBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;AAGN;AACM;EACE,aAAA;AACR;AAkBI;EACE,yBCvBgB;EDyBhB,kBAAA;EACA,cAAA;EACA,uBAAA;EACA,6BAAA;EACA,kBAAA;EACA,aAAA;AAjBN;AAmBM;EACE,cCnBY;EDoBZ,YAAA;EACA,eAAA;AAjBR;AAqBI;EACE,yBAAA;AAnBN;AAuBE;EACE,mBAAA;EACA,oBAAA;EACA,6BAAA;EACA,kBAAA;EAGA,mHAAA;EACA,2BAAA;EACA,mCAAA;EACA,2BAAA;EACA,4BAAA;EAIA,aAAA;EACA,OAAA;EACA,uBAAA;EACA,WAAA;EAGA,UAAA;EACA,cAAA;AA5BJ;AA8BI;EACE,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBCxEgB;EDyEhB,eAAA;EACA,aAAA;EACA,YAAA;EACA,uBAAA;EAEA,gBAAA;EACA,kBAAA;EACA,eAAA;EAEA,WAAA;AA9BN;AAgCM;EACE,WAAA;AA9BR;AAiCM;EACE,cC3EY;ED4EZ,mDCpBqB;EDqBrB,iBAAA;EACA,gBAAA;AA/BR;AAoCE;EACE,wCAAA;EACA,2BAAA;EACA,mCAAA;EAGA,mCAAA;EACA,cAAA;EACA,WAAA;AApCJ;AA4CE;EACE;IAEE;MAEI,oBAAA;IA5CN;EACF;AACF;;AAiDA;EACE;IACE,8BAAA;EA9CF;AACF;AAiDA;EACE,sBAAA;EACA,UAAA;EACA,gBAAA;EACA,2BAAA;AA/CF","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
