// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/svg/menu-bottom/home_active.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/svg/menu-bottom/home.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/svg/menu-bottom/wallet_active.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/svg/menu-bottom/wallet.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("/static/svg/menu-bottom/contacts_active.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_5___ = new URL("/static/svg/menu-bottom/contacts.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_6___ = new URL("/static/svg/menu-bottom/scan_qrcode.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_7___ = new URL("/static/svg/figma/arrow-sm-right.linear.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_8___ = new URL("/static/svg/figma/arrow-sm-left.linear.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_9___ = new URL("/static/svg/figma/refresh-cw-alt.linear.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./footer-menu.scss"></require>
  <section
    id="footer_menu"
    class.bind="(['home', 'assets', 'bitcoin/wallet/add', 'bitcoin/wallet/import', 'bitcoin/wallet/import_confirmed', 'choose_wallet', 'contacts/:page', 'contacts_select', 'savepot_home', 'profile', 'profile/my_qr_code', 'my_qr_code', 'notifications'].includes(currentInstruction.config.route) || (currentInstruction.config.route === 'profile/choose_type_account' && !state.me.isFirstTime)) ? '' : 'hidden'"
    class="safari_only safe-bottom"
  ><!-- 'payments', 'payments/:page',-->
    <div class="left">
      <!-- <img if.bind="currentInstruction == null" src="/static/img/menu_home_selected.svg">
      <img if.bind="currentInstruction == null" src="/static/img/menu_wallet_selected.svg">
      <img if.bind="currentInstruction == null" src="/static/img/menu_accounts_selected.svg">
      <img if.bind="currentInstruction == null" src="/static/img/menu_user_selected.svg"> -->
      <div class="container flex-row">
        <img
          if.bind="currentInstruction.config.route == 'home'"
          src="${___HTML_LOADER_IMPORT_0___}"
        />
        <img
          if.bind="currentInstruction.config.route != 'home'"
          src="${___HTML_LOADER_IMPORT_1___}"
          click.delegate="router.navigateToRoute('home')"
        />
      </div>
      <div class="container wallet flex-row">
        <img
          if.bind="currentInstruction.config.route == 'choose_wallet'"
          src="${___HTML_LOADER_IMPORT_2___}"
        />
        <img
          if.bind="currentInstruction.config.route != 'choose_wallet'"
          src="${___HTML_LOADER_IMPORT_3___}"
          click.delegate="goToChooseWallet()"
        />
      </div>

      <!-- <img
      if.bind="currentInstruction.config.route == 'notifications'"
      src="/static/img/bell.svg"
      click.delegate="router.navigateToRoute('notifications')"
      /> -->
      <!-- <img if.bind="currentInstruction.config.route == 'ordinals'"
        src="/static/img/menu_ordinals_selected.svg">
      <img if.bind="currentInstruction.config.route != 'ordinals'" src="/static/img/menu_ordinals.svg"
        click.delegate="router.navigateToRoute('ordinals')"> -->

      <!-- <img if.bind="currentInstruction.config.route == 'contacts'" src="/static/img/menu_user_selected.svg">
      <img if.bind="currentInstruction.config.route != 'contacts'" src="/static/img/menu_user.svg"
      click.delegate="gotoFriends()"> -->
      <div class="container flex-row">
        <img
          class="contacts"
          if.bind="currentInstruction.config.route == 'contacts/:page'"
          src="${___HTML_LOADER_IMPORT_4___}"
        />
        <img
          class="contacts"
          if.bind="currentInstruction.config.route != 'contacts/:page'"
          src="${___HTML_LOADER_IMPORT_5___}"
          click.delegate="router.navigateToRoute('contacts', { page: 'mine'})"
        />
      </div>
      <!-- <div class="container flex-row">
        <img
          class="bell"
          if.bind="currentInstruction.config.route == 'notifications'"
          src="/static/img/bell_selected.svg"
        />
        <img
          class="bell"
          if.bind="currentInstruction.config.route != 'notifications'"
          src="/static/img/bell_gray.svg"
          click.delegate="router.navigateToRoute('notifications')"
        />
        <div class="blue_circle flex-column" if.bind="allNotificationInformation.length > 0" click.delegate="router.navigateToRoute('notifications')" class.bind="currentInstruction.config.route == 'notifications' ? 'white_border' : ''">
          <p>\${allNotificationInformation.length}</p>
        </div>
      </div> -->
    </div>
    <div class="right">
      <div class="bg" click.delegate="mainButton()">
        <img
          if.bind="currentInstruction.config.route == 'home' || currentInstruction.config.route == 'notifications' || currentInstruction.config.route == 'profile' || currentInstruction.config.route == 'payments'"
          src="${___HTML_LOADER_IMPORT_6___}"
        />
        <span if.bind="currentInstruction.config.route == 'choose_wallet'">+</span>
        <span if.bind="currentInstruction.config.route == 'contacts/:page'"> + </span>
        <span if.bind="currentInstruction.config.route == 'contacts_select'"> + </span>
        <span if.bind="currentInstruction.config.route == 'savepot_home'"> + </span>
        <span if.bind="currentInstruction.config.route == 'ordinals'"> + </span>
        <img if.bind="currentInstruction.config.route == 'bitcoin/wallet/add'" click.delegate="goImport()" src="${___HTML_LOADER_IMPORT_7___}"/>
        <img if.bind="currentInstruction.config.route == 'bitcoin/wallet/import' || currentInstruction.config.route == 'profile/choose_type_account' " click.delegate="goBack()" src="${___HTML_LOADER_IMPORT_8___}"/>
        <img if.bind="currentInstruction.config.route == 'bitcoin/wallet/import_confirmed'" click.delegate="goImport()" src="${___HTML_LOADER_IMPORT_9___}"/>
      </div>
    </div>
    <div class="far_right"></div>
  </section>
</template>
`;
// Exports
export default code;