import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { NavigationInstruction, PipelineResult, Router, RouterEvent } from 'aurelia-router';
import { Store, connectTo } from 'aurelia-store';
import { MenuItem } from 'components/models/MenuItem';
import { IGenericAccount } from 'components/models/YapilyModels/IGenericAccount';
import { InAppNotification } from 'services/models/InAppNotification';
import { State } from 'state';
  
@connectTo()
@autoinject()
export class HeaderTitleHome {
  message: string;
  @bindable private title: string;
  @bindable private subtitle: string;
  @bindable private menuItems: Array<MenuItem> = [];
  @bindable private tabs: number;
  @bindable private makePayment;
  @bindable private receivePayment;

  private navEvent;
  private currentInstruction;
  private currentRouteParam;
  
  constructor(private router: Router, private ea: EventAggregator, private store: Store<State>, private state: State) { //Initialize the page
    this.navEvent = this.ea.subscribe(RouterEvent.Success, (event: { instruction: NavigationInstruction; result: PipelineResult }) => {
      // console.log("current route: " + JSON.stringify(event.instruction.params));
      // console.log(event.result)
      this.currentInstruction = event.instruction 
      this.currentRouteParam = this.currentInstruction.params;
    });
  }

    activate() {
      this.navEvent.dispose();
    }

    goToPayment(account: IGenericAccount) {
      if (this.state.wallets.length > 0) {
        this.store.dispatch("set.make_payment.sender_wallet", account);
        //console.log(account);
        this.router.navigateToRoute("payments")
      }

      else {
        this.router.navigateToRoute("choose_type_account");
      }
    }

    showNotifTest() {
      const notif = new InAppNotification("Voici une notification", "Voici la description de la notification", "/img/currency/bitcoin/bitcoin_account_white.png", 3000);
      this.ea.publish("app.show.notification", notif)
    }
  }
    