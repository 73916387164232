// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
:export {
  breakpoint-mobile: 30rem;
  mobile: 30rem;
}

section#headertitleassets {
  display: flex;
  flex-direction: column;
  max-width: 80rem;
  width: 100%;
}
section#headertitleassets .headeranimationlinear {
  position: relative;
  background-image: linear-gradient(to bottom right, #0f131a, #3e1963, #8b5cf6, #3e1963, #0f131a, #0f131a, #3e1963, #f97316, #eab300, #f97316, #3e1963, #0f131a, #0f131a, #8b5cf6, #3e1963, #0f131a, #0f131a);
  background-size: 200% 200%;
  animation: gradient-x 45s ease-in-out infinite;
}
@keyframes gradient-x {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 90%;
  }
  100% {
    background-position: 0% 0%;
  }
}
@keyframes gradient-bi {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-tri {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}
section#headertitleassets div.top {
  margin-bottom: 1.5rem;
}
section#headertitleassets div.top div.left h1 {
  color: var(--text-icons-dark-base-main, #F9F9F9);
  font-size: 1.75rem;
  font-weight: 600;
  font-family: "Inter";
  line-height: 2.375rem;
  margin: 0rem;
  text-align: start;
  padding: 0rem;
}
section#headertitleassets div.top div.left p {
  color: var(--text-icons-dark-base-second, #AFB3C0);
  font-weight: 400;
  font-family: "Inter";
  line-height: 1.5rem;
  margin: 0rem;
  padding: 0rem;
}
section#headertitleassets div.top div.right {
  align-items: center;
  justify-content: flex-end;
}
section#headertitleassets div.top div.right button {
  line-height: 1.25rem;
  margin-left: 0.5rem;
  padding: var(--main-system-10-px, 0.625rem) var(--main-system-16-px, 1rem);
}
section#headertitleassets div.top div.right button.btn-fresh-primary, section#headertitleassets div.top div.right button.last {
  border-radius: 0.725rem;
}
section#headertitleassets div.bottom {
  border-bottom: 1px solid var(--stroke-dark-base, #2F3340);
  width: 100%;
}
section#headertitleassets div.bottom ul {
  display: flex;
  flex-direction: row;
  padding: 0rem;
  margin: 0rem;
}
section#headertitleassets div.bottom ul li {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 3rem;
  list-style: none;
  justify-content: center;
  margin: 0rem;
  margin-right: 1.5rem;
  padding: 0rem;
  width: fit-content;
}
section#headertitleassets div.bottom li.active {
  border-bottom: 2px solid #0681b1;
}
section#headertitleassets div.bottom li.active span.name {
  color: var(--text-icons-dark-base-main, #F9F9F9);
}
section#headertitleassets div.bottom form {
  background-color: #0f131a !important;
  border: 2px solid rgba(255, 255, 255, 0.1);
  display: flex;
  border-radius: 8px;
  margin-bottom: 0.25rem;
  width: 100%;
  z-index: 10;
}
section#headertitleassets div.bottom form img {
  padding: 0.75rem;
}
section#headertitleassets div.bottom form input {
  width: 100%;
  background-color: #0F131A;
  border-radius: 8px;
  font-weight: bold;
  text-align: left;
}

@media only screen and (max-width: 40rem) {
  section#headertitleassets {
    padding: 1rem;
  }
  section#headertitleassets div.top div.right {
    flex-direction: column !important;
  }
  section#headertitleassets div.bottom {
    flex-direction: column !important;
  }
  section#headertitleassets div.bottom ul {
    justify-content: space-evenly;
  }
  section#headertitleassets div.bottom div.right form {
    margin: auto;
    max-width: calc(100% - 2rem);
  }
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/breakpoints.scss","webpack://./src/components/header-titles/header-title-assets/header-title-assets.scss"],"names":[],"mappings":"AAAA;;;;EAAA;AC+CA;EAEQ,wBAxBa;EA2BjB,aA3BiB;ACjBrB;;AALA;EACE,aAAA;EACA,sBAAA;EAEA,gBAAA;EACA,WAAA;AAOF;AALE;EACA,kBAAA;EAEA,2MAAA;EACA,0BAAA;EACA,8CAAA;AAMF;AAJE;EACE;IAAO,0BAAA;EAOT;EANE;IAAO,6BAAA;EAST;EARE;IAAO,0BAAA;EAWT;AACF;AATE;EACE;IAAK,2BAAA;EAYP;EAXE;IAAM,6BAAA;EAcR;EAbE;IAAO,2BAAA;EAgBT;AACF;AAdE;EACE;IAAQ,4BAAA;EAiBV;EAhBE;IAAQ,4BAAA;EAmBV;EAlBE;IAAQ,4BAAA;EAqBV;AACF;AAjBE;EACE,qBAAA;AAmBJ;AAhBM;EACE,gDAAA;EACA,kBAAA;EACA,gBAAA;EACA,oBF6DiB;EE5DjB,qBAAA;EACA,YAAA;EACA,iBAAA;EACA,aAAA;AAkBR;AAhBM;EACE,kDAAA;EACA,gBAAA;EACA,oBFoDiB;EEnDjB,mBAAA;EACA,YAAA;EACA,aAAA;AAkBR;AAfI;EACE,mBAAA;EACA,yBAAA;AAiBN;AAhBM;EACE,oBAAA;EACA,mBAAA;EACA,0EAAA;AAkBR;AAhBM;EACE,uBAAA;AAkBR;AAdE;EACE,yDAAA;EACA,WAAA;AAgBJ;AAdI;EACE,aAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;AAgBN;AAdM;EACE,mBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;EACA,uBAAA;EACA,YAAA;EACA,oBAAA;EACA,aAAA;EACA,kBAAA;AAgBR;AAbI;EACE,gCAAA;AAeN;AAdM;EACE,gDAAA;AAgBR;AAZI;EACE,oCAAA;EACA,0CAAA;EACA,aAAA;EACA,kBAAA;EACA,sBAAA;EACA,WAAA;EACA,WAAA;AAcN;AAZM;EACE,gBAAA;AAcR;AAXM;EACE,WAAA;EACA,yBFxEsB;EEyEtB,kBAAA;EACA,iBAAA;EACA,gBAAA;AAaR;;AARA;EACE;IACE,aAAA;EAWF;EARI;IACE,iCAAA;EAUN;EANE;IACE,iCAAA;EAQJ;EANI;IACE,6BAAA;EAQN;EAJM;IACE,YAAA;IACA,4BAAA;EAMR;AACF","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
