// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/svg/dot_menu.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./header-title-contacts.scss"></require>
  <section id="headertitlecontacts"
    class.bind="currentInstruction.config.auth && currentInstruction.config.navModel.isActive ? '' : 'no_display'">
    <div class="flex-column">
      <div class="flex-row top">
        <div class="left">
          <div class="formobile flex-row">
            <h1>\${title}</h1>
            <div class="dropdown">
              <!-- <ul if.bind="isDropDown">
                <li id="drop_edit" if.bind="!isEditing" t="profile.choose.choose_wallet.edit_account" click.delegate="isEditing = !isEditing"></li>
                <li id="drop_stop" if.bind="isEditing" t="contacts.stop_editing" click.delegate="isEditing = !isEditing">></li>
                <li id="drop_add" click.delegate="addContact()" t="contacts.add"></li>
              </ul> -->
              <img src="${___HTML_LOADER_IMPORT_0___}" click.delegate="isDropDown = !isDropDown"/>
            </div>
          </div>
          <p class="subtitle">\${subtitle}</p>
        </div>
        <div class="flex-grow"></div>
        <div class="right flex-row contacttextbuttons">
          <button id="contacts-edit" class="btn-fresh btn-fresh-secondary" if.bind="!isEditing && currentInstruction.params.page === 'mine'" t="contacts.editing" click.delegate="isEditing = !isEditing"></button>
          <button id="contacts-stop_editing" class="btn-fresh btn-fresh-secondary" if.bind="isEditing && currentInstruction.params.page === 'mine'" t="contacts.stop_editing" click.delegate="isEditing = !isEditing"></button>
          <button id="contacts-add_account" class="btn-fresh btn-fresh-primary" if.bind="currentInstruction.params.page === 'mine'" click.delegate="addContact()" t="contacts.add"></button>
        </div>
      </div>
      <div class="flex-row bottom">
        <ul>
          <li 
            repeat.for="menuItem of menuItems" id="\${menuItem.name}"
            class.bind="(currentInstruction.config.route === 'contacts/:page')
              && (currentInstruction.params.page === menuItem.url) ? 'active' : ''"
            click.delegate="router.navigateToRoute('contacts', { page: menuItem.url })">
            <span  class="name">
              \${menuItem.name}
            </span>
            <span class="notifs" if.bind="menuItem.notifications > 0">
              \${menuItem.notifications}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
`;
// Exports
export default code;