// Module
var code = `<template>
  <require from="./header-title-payments.scss"></require>
  <section id="headertitlepayments"
    class.bind="currentInstruction.config.auth && currentInstruction.config.navModel.isActive ? '' : 'no_display'">
    <div class="flex-column">
      <div class="flex-row top">
        <div class="left">
          <h1 t="payments.title"></h1>
          <p class="subtitle" t="payments.subtitle"></p>
        </div>
        <div class="flex-grow"></div>
        <div class="right flex-row">
          <!-- <button class="btn-fresh btn-fresh-secondary">Edit Accounts</button>
          <button class="btn-fresh btn-fresh-primary" click.delegate="addAccount()">Add Account</button> -->
          <!-- <button class="btn-fresh btn-fresh-secondary last">...</button> -->
          <!-- <button 
            class="btn-fresh btn-fresh-primary"
            disabled.bind="!makePayment.sender || !makePayment.recipient">
            <span t="bitcoin.payment.make_payment_bitcoin_set_amount"></span>
          </button> -->
        </div>
      </div>
      <div class="flex-row bottom">
        <ul>
          <li 
            repeat.for="menuItem of menuItems"
            class.bind="(tabs === \$index) ? 'active' : ''"
            click.delegate="tabs = \$index">
            <span class="name">
              \${menuItem.name}
            </span>
            <span class="notifs" if.bind="menuItem.notifications > 0">
              \${menuItem.notifications}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
`;
// Exports
export default code;