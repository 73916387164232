import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { NavigationInstruction, PipelineResult, Router, RouterEvent } from 'aurelia-router';
import { MenuItem } from 'components/models/MenuItem';
  
  @autoinject()
  export class HeaderTitleNotifications {
    message: string;
    @bindable private title: string;
    @bindable private subtitle: string;
    @bindable private menuItems: Array<MenuItem> = [];
    @bindable private tabs: number;
    @bindable private makePayment;
    @bindable private receivePayment;

    private navEvent;
    private currentInstruction;
    private currentRouteParam;
    
    constructor(private router: Router, private ea: EventAggregator) { //Initialize the page
      this.navEvent = this.ea.subscribe(RouterEvent.Success, (event: { instruction: NavigationInstruction; result: PipelineResult }) => {
        // console.log("current route: " + JSON.stringify(event.instruction.params));
        // console.log(event.result)
        this.currentInstruction = event.instruction 
        this.currentRouteParam = this.currentInstruction.params;
      });
    }

activate() {
      this.navEvent.dispose();
    }
  }
    