// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
section#headertitlenotifications {
  display: flex;
  flex-direction: column;
  max-height: 50rem;
  overflow: hidden;
  max-width: 80rem;
  transition: max-height 0.2s ease-out;
  width: 100%;
}
section#headertitlenotifications div.top {
  margin-bottom: 1.5rem;
}
section#headertitlenotifications div.top div.left h1 {
  color: var(--text-icons-dark-base-main, #F9F9F9);
  font-size: 1.75rem;
  font-weight: 600;
  font-family: "Inter";
  line-height: 2.375rem;
  margin: 0rem;
  overflow: hidden;
  padding: 0rem;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
}
section#headertitlenotifications div.top div.left p {
  color: var(--text-icons-dark-base-second, #AFB3C0);
  font-weight: 400;
  font-family: "Inter";
  line-height: 1.5rem;
  margin: 0rem;
  padding: 0rem;
}
section#headertitlenotifications div.top div.right {
  align-items: center;
  justify-content: flex-end;
}
section#headertitlenotifications div.top div.right button {
  line-height: 1.25rem;
  overflow: hidden;
  margin-left: 0.5rem;
  padding: var(--main-system-10-px, 0.625rem) var(--main-system-16-px, 1rem);
  transition: height 0.3s ease-out, width 0.3s ease-out, opacity 0.2s ease-out;
}
section#headertitlenotifications div.top div.right button.btn-fresh-primary, section#headertitlenotifications div.top div.right button.btn-fresh-secondary, section#headertitlenotifications div.top div.right button.last {
  border-radius: 0.725rem;
}
section#headertitlenotifications div.bottom ul {
  border-bottom: 1px solid var(--stroke-dark-base, #2F3340);
  display: flex;
  flex-direction: row;
  padding: 0rem;
  margin: 0rem;
  margin-bottom: 2rem;
  width: 100%;
}
section#headertitlenotifications div.bottom ul li {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 3rem;
  list-style: none;
  justify-content: center;
  margin: 0rem;
  margin-right: 1.5rem;
  padding: 0rem;
  width: fit-content;
}
section#headertitlenotifications div.bottom ul li span.name {
  color: var(--text-icons-dark-base-second, #AFB3C0);
  font-family: "Inter";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.375rem;
}
section#headertitlenotifications div.bottom ul li span.notifs {
  align-items: center;
  border: 1px solid var(--stroke-dark-base, #2F3340);
  border-radius: 50%;
  display: flex;
  font-family: "Inter";
  font-size: 0.8125rem;
  font-weight: 600;
  height: 1.2rem;
  justify-content: center;
  line-height: 0.8125rem;
  margin: 0rem;
  margin-left: 0.75rem;
  padding: 0rem;
  width: 1.2rem;
}
section#headertitlenotifications div.bottom li.active {
  border-bottom: 2px solid #0681b1;
}
section#headertitlenotifications div.bottom li.active span.name {
  color: var(--text-icons-dark-base-main, #F9F9F9);
}

.no_display {
  display: none !important;
}

@media only screen and (min-width: 40rem) and (max-width: 55rem) {
  section#headertitle div.top div.right button.edit, section#headertitle div.top div.right btn-fresh-primary {
    width: 0rem;
    height: 0rem;
    opacity: 0;
  }
}
@media only screen and (max-width: 40rem) {
  section#headertitle {
    max-height: 0rem !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/components/header-titles/header-title-notifications/header-title-notifications.scss"],"names":[],"mappings":"AAAA;;;;EAAA;ACEA;EACE,aAAA;EACA,sBAAA;EAEA,iBAAA;EACA,gBAAA;EACA,gBAAA;EACA,oCAAA;EACA,WAAA;AAGF;AAAE;EACE,qBAAA;AAEJ;AACM;EACE,gDAAA;EACA,kBAAA;EACA,gBAAA;EACA,oBDqFiB;ECpFjB,qBAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;AACR;AACM;EACE,kDAAA;EACA,gBAAA;EACA,oBDyEiB;ECxEjB,mBAAA;EACA,YAAA;EACA,aAAA;AACR;AAEI;EACE,mBAAA;EACA,yBAAA;AAAN;AACM;EACE,oBAAA;EACA,gBAAA;EACA,mBAAA;EACA,0EAAA;EACA,4EAAA;AACR;AACM;EACE,uBAAA;AACR;AAII;EACE,yDAAA;EACA,aAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;EACA,mBAAA;EACA,WAAA;AAFN;AAIM;EACE,mBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;EACA,uBAAA;EACA,YAAA;EACA,oBAAA;EACA,aAAA;EACA,kBAAA;AAFR;AAIQ;EACE,kDAAA;EACA,oBD2Be;EC1Bf,oBAAA;EACA,gBAAA;EACA,qBAAA;AAFV;AAIQ;EACE,mBAAA;EACA,kDAAA;EACA,kBAAA;EACA,aAAA;EACA,oBDiBe;EChBf,oBAAA;EACA,gBAAA;EACA,cAAA;EACA,uBAAA;EACA,sBAAA;EACA,YAAA;EACA,oBAAA;EACA,aAAA;EACA,aAAA;AAFV;AAMI;EACE,gCAAA;AAJN;AAKM;EACE,gDAAA;AAHR;;AASA;EACE,wBAAA;AANF;;AASA;EAIQ;IACE,WAAA;IACA,YAAA;IACA,UAAA;EATR;AACF;AAeA;EACE;IACE,2BAAA;EAbF;AACF","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
