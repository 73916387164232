import { autoinject, bindable, computedFrom, inject } from "aurelia-framework";
import { AuthService } from "aurelia-auth";
import { Router } from "aurelia-router";
import { HttpClient } from "aurelia-fetch-client";
import { I18N } from "aurelia-i18n";
import { SingletonService } from "singleton";
import { default as env } from "../../../config/environment.json";
@autoinject
export class HeaderStandard {
    private email = "";
    private password = "";
    private locale: string = "fr";
    private displayError = true;
    private errorMessage: string;
    private router;
    private show: boolean;
    private inputPsw: HTMLInputElement;
    private focusHide: boolean = false;
    private loading: boolean = false;
    private shaking: boolean;
    private codePin: string = "empty";
    
    @bindable private showBackButton: boolean;
    @bindable private showLogo: boolean;
    @bindable private showLanguage: boolean;
    @bindable private showMessage: boolean;
    @bindable private message: string = "";
    private welcome_title: string


    constructor(
        private authService: AuthService,
        router: Router,
        private i18n: I18N,
        private singleton: SingletonService
      ) {
        this.welcome_title = env.welcome_title
        this.router = router;
        try {
          this.email = this.singleton.getEmail();
        } catch (e) {
          console.log(e);
        }
      }
      goToWelcome(){
        this.router.navigateToRoute('welcome')

      }
}