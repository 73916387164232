// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/brands/twetch.svg", import.meta.url);
// Module
var code = `<template>
	<require from= "./footer-bottom.scss"></require>
  <require from= "../../components/mybankabrand/mybankabrand"></require>
	<section id="footer">
    <!-- <div id="content" class="flex-row">
      <div id="contact" class="flex-column">
        <div class="logo flex-row">
          <img src="/static/img/logo_white.png">
          <div class="right">
            <p class="title" t="home.mybanka">MyBanka</p>
            <p class="slogan" t="home.slogan">Your money. Your way.</p>
          </div>
        </div>
        <div class="detail flex-row">
          <i class="fas fa-envelope"></i>
          <div class="right flex-column">
            <a href="mailto:hello@mybanka.eu">
              <strong t="components.footer-bottom.email"></strong>
            </a>
            <a href="mailto:hello@mybanka.eu">hello@mybanka.eu</a>
          </div>
        </div>
        <div class="detail flex-row">
          <i class="fas fa-map-marker"></i>
          <div class="right flex-column">
            <span style="text-transform: capitalize;">
              <strong t="components.footer-bottom.address"></strong>
            </span>
            <span><a href="https://www.openstreetmap.org/#map=13/50.8447/4.3552"> Brussels, Belgium</a></span>
          </div>
        </div>
      </div>
      <div id="company">
        <div class="content flex-column">
          <strong t="components.footer-bottom.company"></strong>
          <span><a t="components.footer-bottom.home" href="/welcome"></a></span>
          <span><a href="/about" t="components.footer-bottom.about"></a></span>
          <span><a href="/blogs" t="components.footer-bottom.blog"></a></span>
          <span><a href="/feedback" t="components.footer-bottom.contacts"></a></span>
        </div>
      </div>
      <div id="help">
        <div class="content flex-column">
          <strong t="components.footer-bottom.help">Help</strong>
          <span><a href="/contactspub" t="components.footer-bottom.help_tech"></a></span>
          <span><a href="/legal/terms" t="components.footer-bottom.help_terms"></a></span>
          <span><a href="https://mybanka.eu/bitcoin-white-paper.pdf" t="components.footer-bottom.bitcoinwp"></a></span>

           <span><a href="/privacy" t="footer.help_priv"></a></span> -/->
        </div>
      </div>
      <div id="newsletter" class="flex-column">
        <h3 t="components.footer-bottom.newsletter">Subscribe to our newsletter</h3>
        <p t="components.footer-bottom.newsletter_desc">The latest insights, resources, expert opinions and company news.</p>
        <p class="success" t="components.footer-bottom.newsletter_success" if.bind="showMessage == true"></p>
        <form class="flex-column" submit.delegate="postNewsLetterEmail()" if.bind="showMessage == false">
          <input placeholder="Name" type="text" value.bind="name_news">
          <input placeholder="Email" type="email" value.bind="email_news">
          <button class="btn secondary" disabled.bind="isLoading" type="submit">
            <loader-ring if.bind="isLoading"></loader-ring>
            <span if.bind="!isLoading"><i class="fa fa-paper-plane"></i></span>
          </button>
        </form>
        <br>
        <br>
        <span show.bind="displayError" class="error">\${errorMessage}</span>
        <span show.bind="showMessage" class="succes">\${emailWasSent}</span>
         <div class="circle"></div> -/->
      </div>
    </div> -->
    <div id="topline" class="topline">
      <!-- <div class="logobanka"><img src="/static/img/logo_white.webp"><p>MyBanka</p></a></div>
      <div class="middle"></div> -->
      <mybankabrand></mybankabrand>
      <div class="menu">
        <ul>
          <li>
            <a href="/#header" t="components.footer-bottom.home" router-ignore></a>
          </li>
          <li>
            <a href="/#feature" t="home.welcome.features.title" router-ignore></a>
          </li>
          <!-- <li>
            <a href="/welcome#pricing" t="components.footer-bottom.pricing"></a>
          </li> -->
          <li>
            <a  href="/bitcoin-white-paper.pdf" router-ignore>Bitcoin Whitepaper</a>
          </li>
          <li>
            <a href="/#faq" t="home.welcome.faq.faq" router-ignore></a>
          </li>
          <li>
            <a href="/feedback" t="components.footer-bottom.contacts"></a>
          </li>
        </ul>
      </div>
      <div class="social flex-row">
        <a href="https://www.instagram.com/mybanka.eu.official/"> <i class="fab fa-instagram"></i></a>
        <a href="https://twitter.com/MyBankaEU">                  <i class="fab fa-brands fa-x-twitter"></i></a>
        <a href="https://twetch.com/u/76522">                     <img src="${___HTML_LOADER_IMPORT_0___}"></a>
        <a href="https://discord.gg/3kg7PbjPAd">                  <i class="fab fa-discord"></i></a>
        <a href="https://www.youtube.com/@MyBankaEU">             <i class="fab fa-youtube"></i></a>
      </div>
    </div>
    <hr>
    <div id="bottomline" class="bottomline">
      <div>&copy; 
        <span t="home.welcome.current_year"></span> 
        <span>MyBanka</span>
        <span> &dash; </span> 
        <span t="home.welcome.rights_reserved"></span>
      </div>
      <div class="flex-grow"></div>
      <div class="legal">
        <a href="/legal/terms" t="legal.privacy.tabtitle"></a>
        <a href="/legal/terms" t="legal.terms.title"></a>
      </div>
    </div>
  </section>
</template>
`;
// Exports
export default code;