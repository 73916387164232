// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
:export {
  breakpoint-mobile: 30rem;
  mobile: 30rem;
}

section#headertitlehome {
  display: flex;
  flex-direction: column;
  max-height: 50rem;
  overflow: hidden;
  max-width: 80rem;
  transition: max-height 0.2s ease-out;
  width: 100%;
}
section#headertitlehome div.top {
  margin-bottom: 1.5rem;
}
section#headertitlehome div.top div.left h1 {
  color: var(--text-icons-dark-base-main, #F9F9F9);
  font-size: 1.75rem;
  font-weight: 600;
  font-family: "Inter";
  line-height: 2.375rem;
  margin: 0rem;
  overflow: hidden;
  padding: 0rem;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
}
section#headertitlehome div.top div.left p {
  color: var(--text-icons-dark-base-second, #AFB3C0);
  font-weight: 400;
  font-family: "Inter";
  line-height: 1.5rem;
  margin: 0rem;
  padding: 0rem;
}
section#headertitlehome div.top div.right {
  align-items: center;
  justify-content: flex-end;
}
section#headertitlehome div.top div.right button {
  line-height: 1.25rem;
  overflow: hidden;
  margin-left: 0.5rem;
  padding: var(--main-system-10-px, 0.625rem) var(--main-system-16-px, 1rem);
  height: 2.5rem;
  width: fit-content;
  border-radius: 0.6rem;
}
section#headertitlehome div.bottom ul {
  border-bottom: 1px solid var(--stroke-dark-base, #2F3340);
  display: flex;
  flex-direction: row;
  padding: 0rem;
  margin: 0rem;
  margin-bottom: 2rem;
  width: 100%;
}
section#headertitlehome div.bottom ul li {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 3rem;
  list-style: none;
  justify-content: center;
  margin: 0rem;
  margin-right: 1.5rem;
  padding: 0rem;
  width: fit-content;
}
section#headertitlehome div.bottom ul li span.name {
  color: var(--text-icons-dark-base-second, #AFB3C0);
  font-family: "Inter";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.375rem;
}
section#headertitlehome div.bottom ul li span.notifs {
  align-items: center;
  border: 1px solid var(--stroke-dark-base, #2F3340);
  border-radius: 50%;
  display: flex;
  font-family: "Inter";
  font-size: 0.8125rem;
  font-weight: 600;
  height: 1.2rem;
  justify-content: center;
  line-height: 0.8125rem;
  margin: 0rem;
  margin-left: 0.75rem;
  padding: 0rem;
  width: 1.2rem;
}
section#headertitlehome div.bottom li.active {
  border-bottom: 2px solid #0681b1;
}
section#headertitlehome div.bottom li.active span.name {
  color: var(--text-icons-dark-base-main, #F9F9F9);
}

@media only screen and (min-width: 65rem) {
  section#headertitlehome div.top div.right button.edit, section#headertitlehome div.top div.right btn-fresh-primary {
    width: 0rem;
    height: 0rem;
    opacity: 0;
  }
}
@media only screen and (min-width: 40rem) and (max-width: 65rem) {
  section#headertitlehome div.top div.right button.edit, section#headertitlehome div.top div.right btn-fresh-primary {
    width: 0rem;
    height: 0rem;
    opacity: 0;
  }
}
@media only screen and (min-width: 40rem) and (max-width: 49.125rem) {
  section#headertitlehome div.top div.right {
    display: flex;
    flex-direction: column !important;
  }
  section#headertitlehome div.top div.right button {
    width: 10rem;
  }
}
@media only screen and (min-width: 40rem) and (max-width: 65rem) {
  section#headertitlehome div.top div.right button.edit, section#headertitlehome div.top div.right btn-fresh-primary {
    width: 0rem;
    height: 0rem;
    opacity: 0;
  }
}
@media only screen and (max-width: 40rem) {
  section#headertitlehome {
    max-height: 0rem !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/breakpoints.scss","webpack://./src/components/header-titles/header-title-home/header-title-home.scss"],"names":[],"mappings":"AAAA;;;;EAAA;AC+CA;EAEQ,wBAxBa;EA2BjB,aA3BiB;ACjBrB;;AALA;EACE,aAAA;EACA,sBAAA;EAEA,iBAAA;EACA,gBAAA;EACA,gBAAA;EACA,oCAAA;EACA,WAAA;AAOF;AAJE;EACE,qBAAA;AAMJ;AAHM;EACE,gDAAA;EACA,kBAAA;EACA,gBAAA;EACA,oBFoFiB;EEnFjB,qBAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;AAKR;AAHM;EACE,kDAAA;EACA,gBAAA;EACA,oBFwEiB;EEvEjB,mBAAA;EACA,YAAA;EACA,aAAA;AAKR;AAFI;EACE,mBAAA;EACA,yBAAA;AAIN;AAHM;EACE,oBAAA;EACA,gBAAA;EACA,mBAAA;EACA,0EAAA;EACA,cAAA;EACA,kBAAA;EACA,qBAAA;AAKR;AAAI;EACE,yDAAA;EACA,aAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;EACA,mBAAA;EACA,WAAA;AAEN;AAAM;EACE,mBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;EACA,uBAAA;EACA,YAAA;EACA,oBAAA;EACA,aAAA;EACA,kBAAA;AAER;AAAQ;EACE,kDAAA;EACA,oBF2Be;EE1Bf,oBAAA;EACA,gBAAA;EACA,qBAAA;AAEV;AAAQ;EACE,mBAAA;EACA,kDAAA;EACA,kBAAA;EACA,aAAA;EACA,oBFiBe;EEhBf,oBAAA;EACA,gBAAA;EACA,cAAA;EACA,uBAAA;EACA,sBAAA;EACA,YAAA;EACA,oBAAA;EACA,aAAA;EACA,aAAA;AAEV;AAEI;EACE,gCAAA;AAAN;AACM;EACE,gDAAA;AACR;;AAKA;EAIQ;IACE,WAAA;IACA,YAAA;IACA,UAAA;EALR;AACF;AAWA;EAIQ;IACE,WAAA;IACA,YAAA;IACA,UAAA;EAZR;AACF;AAoBA;EAGM;IACE,aAAA;IACA,iCAAA;EApBN;EAsBM;IACE,YAAA;EApBR;AACF;AA0BA;EAIQ;IACE,WAAA;IACA,YAAA;IACA,UAAA;EA3BR;AACF;AAiCA;EACE;IACE,2BAAA;EA/BF;AACF","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
