// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/svg/down_arrow.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/svg/up_arrow.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./header-title-home.scss"></require>
  <section id="headertitlehome"
    class.bind="currentInstruction.config.auth && currentInstruction.config.navModel.isActive ? '' : 'no_display'">
    <div class="flex-column">
      <div class="flex-row top">
        <div class="left">
          <h1 click.delegate="showNotifTest()">\${title}</h1>
          <p>\${subtitle}</p>
        </div>
        <div class="flex-grow"></div>
        <div class="right flex-row">
          <button style="padding-bottom: 0.6rem;"
            class="btn-fresh btn-fresh-secondary last" 
            click.delegate="receivePayment()">
            <img src="${___HTML_LOADER_IMPORT_0___}" alt="">
            <span t="home.receive"></span>
          </button>
          <button
            class="btn-fresh btn-fresh-primary" 
            click.delegate="goToPayment(state.wallets[state.selectedAccountIndex])">
            <img src="${___HTML_LOADER_IMPORT_1___}" />
            <span t="home.send"></span>
          </button>
        </div>
      </div>
      <div class="flex-row bottom">
        <ul>
          <li 
            repeat.for="menuItem of menuItems"
            class.bind="(tabs === \$index) ? 'active' : ''"
            click.delegate="tabs = \$index">
            <span class="name">
              \${menuItem.name}
            </span>
            <span class="notifs" if.bind="menuItem.notifications > 0">
              \${menuItem.notifications}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
`;
// Exports
export default code;