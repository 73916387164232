// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/welcome/svg/down.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/flags/svg/france.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/img/flags/svg/uk.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/img/flags/svg/germany.svg", import.meta.url);
// Module
var code = `<template>
   <require from="./languages.scss"></require>
   <section id="languages">
      <div class="lang_select_box" click.delegate="showLang()" ref="lang_selected">
         <div class="flex-grow"></div>
         <div class="left">
            <img src.bind="getFlag(locale)">
         </div>
         <div class="flex-grow"></div>
         <div class="right">
            <!-- <i class="fas fa-sort-down"></i> -->
            <img src="${___HTML_LOADER_IMPORT_0___}" alt="">
         </div>
         <!-- <div class="flex-grow"></div> -->
      </div>
      <div class="dropDown flex-column" if.bind="isDropDown">
         <div class="dropDownContent flex-column">
            <div class="lang extremity-top flex-row" click.delegate="selectLang('fr')" class.bind="locale == 'fr' ? 'selected' : ''">
               <img src="${___HTML_LOADER_IMPORT_1___}">
               <p>Français</p>
               <i class="fas fa-check"></i>
             </div>
             <div class="lang flex-row" click.delegate="selectLang('en')" class.bind="locale == 'en' ? 'selected' : ''">
               <img src="${___HTML_LOADER_IMPORT_2___}">
               <p>English</p>
               <i class="fas fa-check"></i>
             </div>
             <div class="lang extremity-bottom flex-row" click.delegate="selectLang('de')" class.bind="locale == 'de' ? 'selected' : ''">
               <img src="${___HTML_LOADER_IMPORT_3___}">
               <p>Deutsch</p>
               <i class="fas fa-check"></i>
             </div>
         </div>
      </div>
   </section>
</template>
`;
// Exports
export default code;