import { I18N } from "aurelia-i18n";
import { autoinject, computedFrom, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { SingletonService } from "singleton";
import { DialogService } from "aurelia-dialog";
import { chooseLanguage } from "pages/profile/choose_language/choose_language";
import { dialogsWidthChecker } from 'app';
@autoinject
export class Languages {
  private locale: string = "xx";
  private dialogsWidthChecker: dialogsWidthChecker;
  private isDropDown: boolean = false;
  constructor(
    private dialogService: DialogService,
    private router: Router,
    private i18n: I18N,
    private singleton: SingletonService,
  ) {
    this.getLang();
  }

  /**
   * On se contente de rediriger vers la page de choix de la langue  
   */
  showLang() {
      const dialogsWidth = 960;
      if (window.innerWidth > dialogsWidth) {
        this.isDropDown = !this.isDropDown;
      } else {
        this.isDropDown = false;
        this.router.navigateToRoute("choose_language");
      }
  }

  async getLang() {
    this.locale = this.i18n.getLocale();
  }

  getFlag(locale: string) {
    switch (locale) {
      case 'en':
        return '/img/flags/svg/uk.svg';
      case 'fr':
        return '/img/flags/svg/france.svg';
      case 'de':
        return '/img/flags/svg/germany.svg';
      case 'es':
        return '/img/flags/svg/spain.svg';
      default:
        return '/img/flags/svg/uk.svg';
    }
  }


  async selectLang(lang: string) {
    this.locale = lang.substring(0, 2).toLowerCase();
    //console.log("setting language to " + lang);
    this.i18n.setLocale(lang);
    this.singleton.setMyFavoriteLanguage(lang)
    this.isDropDown = !this.isDropDown;
    }
}
