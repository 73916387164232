// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
:export {
  breakpoint-mobile: 30rem;
  mobile: 30rem;
}

section#headertitleprofile {
  display: flex;
  flex-direction: column;
  max-width: 80rem;
  width: 100%;
}
section#headertitleprofile div.top {
  margin-bottom: 1.5rem;
}
section#headertitleprofile div.top div.left h1 {
  color: var(--text-icons-dark-base-main, #f9f9f9);
  font-size: 1.75rem;
  font-weight: 600;
  font-family: "Inter";
  line-height: 2.375rem;
  margin: 0rem;
  text-align: start;
  padding: 0rem;
}
section#headertitleprofile div.top div.left p.subtitle {
  color: var(--text-icons-dark-base-second, #afb3c0);
  font-weight: 400;
  font-family: "Inter";
  line-height: 1.5rem;
  margin: 0rem;
  padding: 0rem;
  display: none;
}
section#headertitleprofile div.top div.right {
  align-items: center;
  justify-content: flex-end;
}
section#headertitleprofile div.top div.right button {
  line-height: 1.25rem;
  margin-left: 0.5rem;
  padding: var(--main-system-10-px, 0.625rem) var(--main-system-16-px, 1rem);
}
section#headertitleprofile div.top div.right button.btn-fresh-primary,
section#headertitleprofile div.top div.right button.btn-fresh-secondary,
section#headertitleprofile div.top div.right button.last {
  border-radius: 0.725rem;
}
section#headertitleprofile div.bottom ul {
  border-bottom: 1px solid var(--stroke-dark-base, #2f3340);
  display: flex;
  flex-direction: row;
  padding: 0rem;
  margin: 0rem;
  margin-bottom: 2rem;
  width: 100%;
}
section#headertitleprofile div.bottom ul li {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 3rem;
  list-style: none;
  justify-content: center;
  margin: 0rem;
  margin-right: 1.5rem;
  padding: 0rem;
  width: fit-content;
}
section#headertitleprofile div.bottom ul li span.name {
  color: var(--text-icons-dark-base-second, #afb3c0);
  font-family: "Inter";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.375rem;
}
section#headertitleprofile div.bottom ul li span.notifs {
  align-items: center;
  border: 1px solid var(--stroke-dark-base, #2f3340);
  border-radius: 50%;
  display: flex;
  font-family: "Inter";
  font-size: 0.8125rem;
  font-weight: 600;
  height: 1rem;
  justify-content: center;
  line-height: 1.125rem;
  margin: 0rem;
  margin-left: 0.75rem;
  padding: 0rem;
  width: 1rem;
}
section#headertitleprofile div.bottom li.active {
  border-bottom: 2px solid #0681b1;
}
section#headertitleprofile div.bottom li.active span.name {
  color: var(--text-icons-dark-base-main, #f9f9f9);
}

@media only screen and (min-width: 40rem) {
  section#headertitleprofile div.top {
    margin-bottom: 1.5rem !important;
  }
  section#headertitleprofile div.top div.left p.subtitle {
    display: block !important;
  }
}
@media only screen and (max-width: 40rem) {
  section#headertitleprofile div.top {
    flex-direction: column !important;
    margin-bottom: 0rem !important;
  }
  section#headertitleprofile div.top div.left p.subtitle {
    display: none !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/breakpoints.scss","webpack://./src/components/header-titles/header-title-profile/header-title-profile.scss"],"names":[],"mappings":"AAAA;;;;EAAA;AC+CA;EAEQ,wBAxBa;EA2BjB,aA3BiB;ACjBrB;;AALA;EACE,aAAA;EACA,sBAAA;EAEA,gBAAA;EACA,WAAA;AAOF;AALE;EACE,qBAAA;AAOJ;AAJM;EACE,gDAAA;EACA,kBAAA;EACA,gBAAA;EACA,oBFwFiB;EEvFjB,qBAAA;EACA,YAAA;EACA,iBAAA;EACA,aAAA;AAMR;AAJM;EACE,kDAAA;EACA,gBAAA;EACA,oBF+EiB;EE9EjB,mBAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;AAMR;AAHI;EACE,mBAAA;EACA,yBAAA;AAKN;AAJM;EACE,oBAAA;EACA,mBAAA;EACA,0EAAA;AAMR;AAHM;;;EAGE,uBAAA;AAKR;AAAI;EACE,yDAAA;EACA,aAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;EACA,mBAAA;EACA,WAAA;AAEN;AAAM;EACE,mBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;EACA,uBAAA;EACA,YAAA;EACA,oBAAA;EACA,aAAA;EACA,kBAAA;AAER;AAAQ;EACE,kDAAA;EACA,oBF+Be;EE9Bf,oBAAA;EACA,gBAAA;EACA,qBAAA;AAEV;AAAQ;EACE,mBAAA;EACA,kDAAA;EACA,kBAAA;EACA,aAAA;EACA,oBFqBe;EEpBf,oBAAA;EACA,gBAAA;EACA,YAAA;EACA,uBAAA;EACA,qBAAA;EACA,YAAA;EACA,oBAAA;EACA,aAAA;EACA,WAAA;AAEV;AAEI;EACE,gCAAA;AAAN;AACM;EACE,gDAAA;AACR;;AAMA;EAEI;IAEE,gCAAA;EALJ;EAQM;IACE,yBAAA;EANR;AACF;AAaA;EAEI;IACE,iCAAA;IACA,8BAAA;EAZJ;EAeM;IACE,wBAAA;EAbR;AACF","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
