import { autoinject } from "aurelia-framework"
import { Router } from "aurelia-router"
import { NewsletterHttpClients } from 'http_clients/NewsletterHttpClients';
import { json } from 'aurelia-fetch-client';
import { UsersHttpClients } from 'http_clients/UsersHttpClients';
import { I18N } from "aurelia-i18n";
@autoinject()
export class FooterBottom {
  private isLoading: boolean = false;
  private displayError: boolean = false;
  private shaking: boolean = false;
  private errorMessage: string = "";
  private showMessage: boolean = false;

  private email: string = '';
  private email_news: string = "";
  private name: string = '';
  private name_news: string = "";

  constructor(
    private newsletterHttpClients: NewsletterHttpClients,
    private userHttpClients: UsersHttpClients,
    private i18n: I18N,
    private router: Router){}

  async postNewsLetterEmail() {
    this.isLoading = true;
    this.showMessage = false;
    this.displayError = false;
    let httpRequest = await this.newsletterHttpClients.fetch('/newsletters', {
      method: 'POST',
      body: json({
        email: this.email || this.email_news,
        name: this.name || this.name_news
      })
    })

    if (httpRequest.ok) {
      this.isLoading = false;
      this.showMessage = true;
      let request = await this.userHttpClients.fetch('/beta/register', {
        method: 'POST',
        body: json({
          email: this.email || this.email_news,
          name: this.name || this.name_news
        })
      })
    }
    else {
      let json_response = await httpRequest.json()
      if (httpRequest.status == 409) {
        this.displayError = true;
        this.errorMessage = this.i18n.tr("home.error.welcome_error_already_exist");
        this.shakeError()
      }
      if (json_response.errors.msg[0].msg == "IS_EMPTY") {
        this.displayError = true;
        this.errorMessage = this.i18n.tr("home.error.welcome_error_is_empty");
        this.shakeError()
      }
      if (json_response.errors.msg[0].msg == "EMAIL_ALREADY_EXIST") {
        this.displayError = true;
        this.errorMessage = this.i18n.tr("home.error.welcome_error_already_exist");
        this.shakeError()
      }
      if (json_response.errors.msg[0].msg == "NOT_AN_EMAIL") {
        this.displayError = true;
        this.errorMessage = this.i18n.tr("home.error.welcome_error_not_an_email");
        this.shakeError()
      }
    }
  }
  async shakeError() {
    this.isLoading = false;
    this.shaking = true;
    setTimeout(() => {
      this.shaking = false;
    }, 200);
  }
}


