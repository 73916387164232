// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
:export {
  breakpoint-mobile: 30rem;
  mobile: 30rem;
}

section#headertitle {
  display: flex;
  flex-direction: column;
  max-width: 80rem;
  width: 100%;
}
section#headertitle div.top {
  margin-bottom: 1.5rem;
}
section#headertitle div.top div.left div.header_menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}
section#headertitle div.top div.left div.header_menu h1 {
  color: var(--text-icons-dark-base-main, #F9F9F9);
  font-size: 1.75rem;
  font-weight: 600;
  font-family: "Inter";
  line-height: 2.375rem;
  margin: 0rem;
  text-align: start;
  padding: 0rem;
}
section#headertitle div.top div.left div.header_menu div.dropdown {
  position: relative;
  display: inline-block;
}
section#headertitle div.top div.left div.header_menu div.dropdown ul {
  background: #0f131a;
  border: 1px solid var(--stroke-dark-base, #2F3340);
  border-radius: 1rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  margin: 0rem;
  min-width: 10rem;
  overflow: hidden;
  padding: 0rem;
  position: absolute;
  right: 1rem;
  top: 2rem;
  z-index: 1;
}
section#headertitle div.top div.left div.header_menu div.dropdown ul li {
  cursor: pointer;
  list-style: none;
  margin: 0rem;
  padding: 0.5rem 1rem;
}
section#headertitle div.top div.left div.header_menu div.dropdown ul li:first-child {
  padding-top: 1rem !important;
}
section#headertitle div.top div.left div.header_menu div.dropdown ul li:last-child {
  padding-bottom: 1rem !important;
}
section#headertitle div.top div.left div.header_menu div.dropdown ul li:hover {
  background: var(--background-dark-base-third, #1F2330);
}
section#headertitle div.top div.left div.header_menu div.dropdown ul.show, section#headertitle div.top div.left div.header_menu div.dropdown ul:hover {
  display: block;
}
section#headertitle div.top div.left div.header_menu div.dropdown img {
  display: none !important;
  cursor: pointer;
}
section#headertitle div.top div.left p.subtitle {
  color: var(--text-icons-dark-base-second, #AFB3C0);
  font-weight: 400;
  font-family: "Inter";
  line-height: 1.5rem;
  margin: 0rem;
  padding: 0rem;
  display: none;
}
section#headertitle div.top div.right {
  align-items: center;
  justify-content: flex-end;
}
section#headertitle div.top div.right button {
  line-height: 1.25rem;
  margin-left: 0.5rem;
  padding: var(--main-system-10-px, 0.625rem) var(--main-system-16-px, 1rem);
  height: 2.6rem;
  width: fit-content;
  border-radius: 0.6rem;
}
section#headertitle div.bottom ul {
  border-bottom: 1px solid var(--stroke-dark-base, #2F3340);
  display: flex;
  flex-direction: row;
  padding: 0rem;
  margin: 0rem;
  margin-bottom: 2rem;
  width: 100%;
}
section#headertitle div.bottom ul li {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 3rem;
  list-style: none;
  justify-content: center;
  margin: 0rem;
  margin-right: 1.5rem;
  padding: 0rem;
  width: fit-content;
}
section#headertitle div.bottom ul li span.name {
  color: var(--text-icons-dark-base-second, #AFB3C0);
  font-family: "Inter";
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.375rem;
}
section#headertitle div.bottom ul li span.notifs {
  align-items: center;
  border: 1px solid var(--stroke-dark-base, #2F3340);
  border-radius: 50%;
  display: flex;
  font-family: "Inter";
  font-size: 0.8125rem;
  font-weight: 600;
  height: 1rem;
  justify-content: center;
  line-height: 1.125rem;
  margin: 0rem;
  margin-left: 0.75rem;
  padding: 0rem;
  width: 1rem;
}
section#headertitle div.bottom li.active {
  border-bottom: 2px solid #0681b1;
}
section#headertitle div.bottom li.active span.name {
  color: var(--text-icons-dark-base-main, #F9F9F9);
}

.no_display {
  display: none !important;
}

@media only screen and (min-width: 55rem) and (max-width: 65rem) {
  section#choose_wallet {
    max-width: min(80rem, 100vw - 5.5rem);
    padding: 1rem !important;
  }
}
@media only screen and (min-width: 40rem) and (max-width: 55rem) {
  section#choose_wallet {
    max-width: 100%;
    padding: 1rem;
  }
}
@media only screen and (min-width: 40rem) {
  section#choose_wallet div.left p.subtitle {
    display: block !important;
  }
}
@media only screen and (max-width: 40rem) {
  section#headertitle div.top {
    flex-direction: column !important;
    margin-bottom: 0rem !important;
  }
  section#headertitle div.top div.left p.subtitle {
    display: none !important;
  }
  section#headertitle div.top div.left div.header_menu div.dropdown img {
    display: flex !important;
    height: 1.75rem;
  }
  section#headertitle div.top div.right.desktop {
    display: none !important;
  }
  section#headertitle div.bottom ul {
    margin-bottom: 0rem !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/breakpoints.scss","webpack://./src/components/header-titles/header-title-choose-wallet/header-title-choose-wallet.scss"],"names":[],"mappings":"AAAA;;;;EAAA;AC+CA;EAEQ,wBAxBa;EA2BjB,aA3BiB;ACjBrB;;AALA;EACE,aAAA;EACA,sBAAA;EAEA,gBAAA;EACA,WAAA;AAOF;AALE;EACE,qBAAA;AAOJ;AAJM;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;AAMR;AAFQ;EACE,gDAAA;EACA,kBAAA;EACA,gBAAA;EACA,oBFgFe;EE/Ef,qBAAA;EACA,YAAA;EACA,iBAAA;EACA,aAAA;AAIV;AAFQ;EACE,kBAAA;EACA,qBAAA;AAIV;AAFU;EACE,mBFYa;EEXb,kDAAA;EACA,mBAAA;EACA,+CAAA;EAEA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;EACA,UAAA;AAGZ;AADY;EACE,eAAA;EACA,gBAAA;EACA,YAAA;EACA,oBAAA;AAGd;AAAY;EACE,4BAAA;AAEd;AACY;EACE,+BAAA;AACd;AAEY;EACE,sDAAA;AAAd;AAKU;EACE,cAAA;AAHZ;AAMU;EACE,wBAAA;EACA,eAAA;AAJZ;AAUM;EACE,kDAAA;EACA,gBAAA;EACA,oBFmBiB;EElBjB,mBAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;AARR;AAYI;EACE,mBAAA;EACA,yBAAA;AAVN;AAWM;EACE,oBAAA;EACA,mBAAA;EACA,0EAAA;EACA,cAAA;EACA,kBAAA;EACA,qBAAA;AATR;AAcI;EACE,yDAAA;EACA,aAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;EACA,mBAAA;EACA,WAAA;AAZN;AAcM;EACE,mBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;EACA,uBAAA;EACA,YAAA;EACA,oBAAA;EACA,aAAA;EACA,kBAAA;AAZR;AAcQ;EACE,kDAAA;EACA,oBF3Be;EE4Bf,oBAAA;EACA,gBAAA;EACA,qBAAA;AAZV;AAcQ;EACE,mBAAA;EACA,kDAAA;EACA,kBAAA;EACA,aAAA;EACA,oBFrCe;EEsCf,oBAAA;EACA,gBAAA;EACA,YAAA;EACA,uBAAA;EACA,qBAAA;EACA,YAAA;EACA,oBAAA;EACA,aAAA;EACA,WAAA;AAZV;AAgBI;EACE,gCAAA;AAdN;AAeM;EACE,gDAAA;AAbR;;AAmBA;EACE,wBAAA;AAhBF;;AAoBA;EACE;IACE,qCAAA;IACA,wBAAA;EAjBF;AACF;AAqBA;EACE;IACE,eAAA;IACA,aAAA;EAnBF;AACF;AAuBA;EAGM;IACE,yBAAA;EAvBN;AACF;AA6BA;EAEI;IACE,iCAAA;IACA,8BAAA;EA5BJ;EA+BM;IACE,wBAAA;EA7BR;EAiCU;IACE,wBAAA;IACA,eAAA;EA/BZ;EAoCI;IACE,wBAAA;EAlCN;EAiDI;IACE,8BAAA;EA/CN;AACF","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
