// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
:export {
  breakpoint-mobile: 30rem;
  mobile: 30rem;
}

section#footer {
  background-color: #0f131a;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  max-width: 80rem;
  min-width: 40rem;
  padding: 0 1rem;
  width: 100%;
  z-index: 15;
}
section#footer div.topline {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-bottom: 2rem;
  width: 100%;
}
section#footer div.topline mybankabrand {
  flex: 1;
}
section#footer div.topline div.menu {
  align-items: center;
  flex: 3;
}
section#footer div.topline div.menu ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px;
  padding: 0px;
}
section#footer div.topline div.menu ul li {
  margin: 0px;
  padding: 1rem;
  list-style: none;
}
section#footer div.topline div.menu ul li a {
  color: #F9F9F9;
}
section#footer div.topline div.social {
  flex: 1;
  margin: 0px;
  padding: 0px;
}
section#footer div.topline div.social a {
  align-items: center;
  background-color: rgba(15, 19, 26, 0.35);
  border: 2px solid #1F2330;
  border-radius: 50%;
  color: #dfe0e2;
  display: flex;
  justify-content: center;
  height: 2rem;
  margin-right: 0.5rem;
  width: 2rem;
  padding: 1.2rem;
}
section#footer div.topline div.social a i {
  font-size: 1.4rem;
}
section#footer div.topline div.social a img {
  border-radius: 50%;
  filter: grayscale(100%);
  height: 1.5rem;
  width: 1.5rem;
  -webkit-filter: grayscale(100%);
}
section#footer div.topline div.social a:hover {
  text-decoration: none;
  color: #dfe0e2 !important;
}
section#footer hr {
  background-color: #1B2027;
  height: 1px;
  width: 100%;
}
section#footer div#bottomline {
  align-items: center;
  background-color: #0f131a;
  display: flex;
  justify-content: center;
  max-width: 80rem;
  padding-top: 2rem;
  width: 100%;
  z-index: 15;
  margin: 0px;
}
section#footer div#bottomline div {
  color: #AFB3C0;
  font-family: "Inter";
  font-size: 1rem;
  font-weight: 500;
  margin: 0px;
  line-height: 1.5rem;
  text-align: center;
}
section#footer div#bottomline div span {
  color: #AFB3C0;
}
section#footer div#bottomline a {
  color: #AFB3C0;
  font-family: "Inter";
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-align: center;
}
section#footer div#bottomline div.legal {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 55rem) {
  div.bottomline {
    display: flex;
    flex-direction: row;
  }
  div.bottomline div.legal {
    align-items: start;
  }
}
@media only screen and (min-width: 30rem) and (max-width: 55rem) {
  section#footer div.topline {
    display: flex;
    flex-direction: column;
  }
  section#footer div.topline div.menu {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 2rem;
  }
  section#footer div.bottomline {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  section#footer div.bottomline div.legal {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    align-items: center;
  }
}
@media only screen and (max-width: 30rem) {
  section#footer div.topline {
    display: flex;
    flex-direction: column;
  }
  section#footer div.topline div.menu ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }
  section#footer div.bottomline {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  section#footer div.bottomline div.legal {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    align-items: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/breakpoints.scss","webpack://./src/components/footer-bottom/footer-bottom.scss"],"names":[],"mappings":"AAAA;;;;EAAA;AC+CA;EAEQ,wBAxBa;EA2BjB,aA3BiB;ACjBrB;;AALA;EACE,yBF6CuB;EE5CvB,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,WAAA;EACA,WAAA;AAQF;AAyLE;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,WAAA;AAvLJ;AAyLI;EACE,OAAA;AAvLN;AA0LI;EACE,mBAAA;EACA,OAAA;AAxLN;AA0LM;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;AAxLR;AA0LQ;EACE,WAAA;EACA,aAAA;EACA,gBAAA;AAxLV;AAyLU;EACE,cFjOqB;AE0CjC;AA6LI;EACE,OAAA;EACA,WAAA;EACA,YAAA;AA3LN;AAgMM;EACE,mBAAA;EACA,wCF7O8B;EE8O9B,yBAAA;EACA,kBAAA;EACA,cFxMY;EEyMZ,aAAA;EACA,uBAAA;EACA,YAAA;EACA,oBAAA;EACA,WAAA;EACA,eAAA;AA9LR;AAgMQ;EACE,iBAAA;AA9LV;AAiMQ;EACE,kBAAA;EACA,uBAAA;EACA,cAAA;EACA,aAAA;EACA,+BAAA;AA/LV;AAmMM;EACE,qBAAA;EACA,yBAAA;AAjMR;AAsME;EACE,yBFnOoB;EEoOpB,WAAA;EACA,WAAA;AApMJ;AAuMC;EACG,mBAAA;EACA,yBF9OqB;EE+OrB,aAAA;EACA,uBAAA;EAEA,gBAAA;EACA,iBAAA;EACA,WAAA;EACA,WAAA;EACA,WAAA;AAtMJ;AAwMI;EACE,cFrSqB;EEuSrB,oBFlMmB;EEmMnB,eAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;EACA,kBAAA;AAvMN;AAyMM;EACE,cF/SmB;AEwG3B;AA4MI;EACE,cFrTqB;EEuTrB,oBFlNmB;EEmNnB,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;AA3MN;AA8MI;EACE,aAAA;EACA,sBAAA;AA5MN;;AAiNA;EAEI;IACE,aAAA;IACA,mBAAA;EA/MJ;EAiNI;IACE,kBAAA;EA/MN;AACF;AAoNA;EAEI;IACE,aAAA;IACA,sBAAA;EAnNJ;EAqNI;IACE,aAAA;IACA,sBAAA;IACA,kBAAA;IACA,mBAAA;EAnNN;EAuNE;IACE,aAAA;IACA,8BAAA;IACA,mBAAA;IACA,uBAAA;EArNJ;EAuNI;IACE,aAAA;IACA,sBAAA;IACA,oBAAA;IACA,mBAAA;EArNN;AACF;AA0NA;EAGI;IACE,aAAA;IACA,sBAAA;EA1NJ;EA6NM;IACE,aAAA;IACA,sBAAA;IACA,mBAAA;IACA,uBAAA;IACA,mBAAA;EA3NR;EAgOM;IACE,aAAA;IACA,8BAAA;IACA,mBAAA;IACA,uBAAA;EA9NR;EAgOQ;IACE,aAAA;IACA,sBAAA;IACA,oBAAA;IACA,mBAAA;EA9NV;AACF","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
