// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/welcome/svg/search.svg", import.meta.url);
// Module
var code = `<template>
    <require from="./header-title-assets.scss"></require>

    <section id="headertitleassets"
      class.bind="currentInstruction.config.auth && currentInstruction.config.navModel.isActive ? '' : 'no_display'">
      <div  id="headertitleassets" class="flex-column">
        <div class="flex-row top">
          <div class="left">
            <h1 t="assets.title"></h1>
            <p t="assets.subtitle"></p>
          </div>
          <div class="flex-grow"></div>
          <div class="right flex-row">
            <button class="btn-fresh btn-fresh-secondary" if.bind="tabs == 1">Mint my NFT</button>
            <button class="btn-fresh btn-fresh-primary" if.bind="tabs == 1">Redeem my NFT</button>
          </div>
        </div>
        <div class="flex-grow"></div>
        <div class="flex-row bottom">
          <!-- <ul>
            <li 
              repeat.for="menuItem of menuItems"
              class.bind="(tabs === \$index) ? 'active' : ''"
              click.delegate="tabs = \$index">
              <span class="name">
                \${menuItem.name}
              </span>
              <span class="notifs" if.bind="menuItem.notifications > 0">
                \${menuItem.notifications}
              </span>
            </li>
          </ul> -->
          <ul>
            <li t="assets.marketplace" class.bind="tabs == 0 ? 'active' : ''" click.delegate="tabs = 0"></li>
            <li class.bind="tabs == 1 ? 'active' : ''" click.delegate="tabs = 1"  t="components.header-title.assets.my_assets"></li>
            <li t="assets.watchlist" class.bind="tabs == 2 ? 'active' : ''" click.delegate="tabs = 2"></li>
          </ul>
          <div class="flex-grow"></div>
          <div class="right">
            <form id="search_form" class="search_bar flex-row" submit.trigger="searchAssets()" role="form">
              <img src="${___HTML_LOADER_IMPORT_0___}" alt="">
              <input type="text" value.bind="searchvalue" class="search_bar" i18n="[placeholder]components.search">
            </form>
          </div>
        </div>
      </div>
    </section>
  </template>
  `;
// Exports
export default code;